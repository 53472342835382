// import DetailHead from "../../components/campaign/detailHeader";
// import React from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useState } from "react";
// import { useEffect } from "react";
// import axios from "axios";
// import Donation from "../Contactus/Donation";
// import x from "../../assets/Logo/bglo.png";
// import NextCapmaign from "./NextCampaign";

// const CampainDetail = () => {
//   const {id} = useParams()
//   const navigate = useNavigate();
//   const [Data, setData] = useState(null);

//   const url = process.env.REACT_APP_API_URL + `/campaigns/${id}?populate=*`;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const { data } = await axios({
//           method: "get",
//           url: url,
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`, // Add a space after "Bearer"
//           },
//         });

//         console.log(data?.data, "blog data");

//         setData(data?.data);
//         console.log(Data, "Data");
//       } catch (error) {
//         console.error("Error fetching blog data:", error.message);
//       }
//     };

//     fetchData();
//   }, [url]);




// console.log(id,'CampainDetail')

//   return (
//     <>
//         <div className="grid md:grid-cols-4 sm:grid-rows-2 bg-darkgren">
//         <div className=" p-28 bg-darkgren col-span-3 sm:row-span-1">
// <div className=" w-full text-white text-5xl bg-zinc-300 self-center max-w-full justify-center pl-5 pr-16 py-5 items-start max-md:px-5">
// {Data?.attributes?.name}
// </div>
// <div className="w-full mt-14 max-md:max-w-full max-md:mt-10">
//   <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
//     <div className="flex flex-col items-stretch w-[57%] max-md:w-full max-md:ml-0">
//       <img
//         loading="lazy"
//         src={`${process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.imageURL?.data[0].attributes?.url}`}
//         className=" object-contain object-center w-full overflow-hidden grow max-md:max-w-full max-md:mt-10"
//       />
//     </div>
//     <div className="flex flex-col items-stretch w-[100%] ml-5 max-md:w-full max-md:ml-0">
//       <div style={{backgroundImage: `url(${x})`}} className="text-white text-xl bg-zinc-300 justify-center self-stretch items-stretch w-full my-auto px-7 py-11 max-md:max-w-full max-md:mt-10 max-md:px-5">
//       {Data?.attributes?.right_desc}
//       </div>
//     </div>
//   </div>
// </div>{" "}
// <div className="text-white text-xl bg-zinc-300 w-full items-stretch mt-6 pl-2 pr-8 pt-7 pb-24 max-md:max-w-full max-md:pr-5 max-md:pb-10">
// {Data?.attributes?.description}
// </div>
//     <Donation />
// </div>


//     <div className="p-[1rem] xl:w-[100%] md:w-[100%] lg:w-[100%]  pt-20 sm:row-span-1">
//       <NextCapmaign   />
//     </div>
//         </div>
//     </>
//   );
// };

// export default CampainDetail;


import DetailHead from "../../components/campaign/detailHeader";
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Donation from "../Contactus/Donation";
import x from "../../assets/Logo/bglo.png";
import NextCapmaign from "./NextCampaign";

const CampainDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [Data, setData] = useState(null);

  const url = `${process.env.REACT_APP_API_URL}/campaigns/${id}?populate=*`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });

        setData(data?.data);
      } catch (error) {
        console.error("Error fetching blog data:", error.message);
      }
    };

    fetchData();
  }, [url]);

  return (
    <>
      <div className="grid md:grid-cols-4 bg-darkgren pt-12">
        <div className="p-8 col-span-3 bg-darkgren">
          <div className="text-white text-5xl bg-zinc-300 pl-5 pr-16 py-5">
            {Data?.attributes?.name}
          </div>
          <div className="w-full mt-8 flex flex-col md:flex-row gap-5">
            <div className="flex-1">
              <img
                loading="lazy"
                src={`${process.env.REACT_APP_API_IMAGE_URL}${Data?.attributes?.imageURL?.data[0].attributes?.url}`}
                className="object-contain w-full"
              />
            </div>
            <div className="flex-1">
              <div
                style={{ backgroundImage: `url(${x})` }}
                className="text-white text-xl bg-zinc-300 px-7 py-11"
              >
                {Data?.attributes?.right_desc}
              </div>
            </div>
          </div>
          <div className="text-white text-xl bg-zinc-300 mt-6 p-5">
            {Data?.attributes?.description}
          </div>
          <Donation />
        </div>

        <div className="p-4 md:p-8">
          <NextCapmaign />
        </div>
      </div>
    </>
  );
};

export default CampainDetail;
