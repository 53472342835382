import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode"; // Updated import statement

const Login = ({ setIsLogin }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Form data:", formData);

    const { email, password } = formData;

    if (email && password) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/accounts`,
          {
            params: { email, password },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );

        console.log("Response:", response);
        console.log("Data:", response.data);

        const data = response.data;

        if (data) {
          console.log("User found", data);
          setMessage("Success: Login successfully!");
          navigate("/payment");
        } else {
          setMessage("No account found. Please create a new account.");
          alert("No account found. Please create a new account.");
          setIsLogin(false);
        }
      } catch (error) {
        if (error.response) {
          setMessage(`Error: ${error.response.data.message}`);
        } else if (error.request) {
          setMessage("Error: No response from server");
        } else {
          setMessage(`Error: ${error.message}`);
        }
      }
    } else {
      setMessage("Error: Please fill out all fields");
    }
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    const token = credentialResponse.credential;
    const decoded = jwtDecode(token); // Updated to use jwtDecode
    console.log("User info:", decoded);

    // Optionally, you can store the token in localStorage or cookies
    localStorage.setItem("google_token", token);

    // Handle the login, e.g., redirect to the dashboard or payment
    setMessage("Success: Logged in with Google!");
    navigate("/payment");
  };

  const handleGoogleLoginFailure = () => {
    setMessage("Error: Google login was unsuccessful");
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="bg-white rounded-2xl shadow-2xl flex flex-col w-full md:w-[40%] items-center max-w-4xl transition duration-1000 ease-out">
        <h2 className="p-3 text-3xl font-bold text-darkgreen">
          Constantine Foundation
        </h2>
        <div className="inline-block border-[1px] justify-center w-20 border-darkgreen border-solid"></div>
        <h3 className="text-xl font-semibold text-darkgreen pt-2">Sign In!</h3>
        <div className="flex space-x-2 m-4 items-center justify-center">
          <div className="socialIcon">
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
            />
          </div>
          {message && (
            <p
              className={`mt-4 text-sm font-medium ${
                message.startsWith("Error") ? "text-red-500" : "text-green-500"
              }`}
            >
              {message}
            </p>
          )}
        </div>
        <form
          className="flex flex-col items-center justify-center w-[80%]"
          onSubmit={handleSubmit}
        >
          <input
            type="email"
            name="email"
            className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-darkgreen focus:outline-none focus:ring-0 text-darkgreen"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="password"
            name="password"
            className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0 text-darkgreen"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
          />
          <button
            type="submit"
            className="rounded-2xl m-2 text-white bg-darkgreen w-2/5 px-4 py-2 shadow-md hover:text-darkgreen hover:bg-white transition duration-200 ease-in"
          >
            Sign In
          </button>
        </form>
        {message && (
          <p
            className={`mt-4 text-sm font-medium ${
              message.startsWith("Error") ? "text-red-500" : "text-green-500"
            }`}
          >
            {message}
          </p>
        )}
        <div className="inline-block border-[1px] justify-center w-20 border-darkgreen border-solid"></div>
        <p className="text-darkgreen mt-4 text-sm">Don't have an account?</p>
        <p
          className="text-darkgreen mb-4 text-sm font-medium cursor-pointer"
          onClick={() => setIsLogin(false)}
        >
          Create a New Account.
        </p>
      </div>
    </GoogleOAuthProvider>
  );
};

Login.propTypes = {
  setIsLogin: PropTypes.func.isRequired,
};

export default Login;
