
import React from "react";
import EventHeader from "../../assets/Constantine Events Header.png"
import FoundationInfo from "./FoundationInfo";
import EventOrganizer from "./EventOrganizer";
import JoinUsBanner from "./JoinUsBanner";
import UpcomingEvent from "./UpcomingEvent";
import LatestEvent from "./LatestEvent";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function Event() {  
  const [Data, setData]= useState(null)
  console.log(Data,'events  ')
 
const url = process.env.REACT_APP_API_URL + `/events?populate=*`;
const headers = {
  "Authorization":"bearer" + process.env.REACT_APP_API_TOKEN 
}
console.log(url,'url')
 
useEffect(() => {
  const fetchData =  async () => {
  
    const {data} =   await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    
    }) 
    console.log(data?.data,'event error')

    setData(data?.data  )
   
  console.log(Data,' Data') 
}

fetchData()

},[url])
  return (
    <div>
    <div
      className="flex lg:h-[55rem] md:h-[35rem] h-[18rem] w-full overflow-hidden items-end"
      id="header"
      >
        <img
          src={EventHeader}
          alt="header"
          className="absolute inset-0 -z-10  w-full object-cover object-right md:object-center"
        />
    </div>
    <FoundationInfo />
    <EventOrganizer />
    <div className="mt-4"></div>
    <UpcomingEvent Data={Data}/>
    <JoinUsBanner />
    <LatestEvent Data={Data}/>
    <div className="mt-12"></div>
    </div>
  );
}

export default Event;
