import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 

const DonorList = () => {
  const navigate = useNavigate();
  const [Data, setData]= useState(null)
  console.log(Data,'Data  ')
 
const url = process.env.REACT_APP_API_URL + `/donors?populate=*`;
const headers = {
  "Authorization":"bearer" + process.env.REACT_APP_API_TOKEN 
}
console.log(url,'url')
 
useEffect(() => {
  const fetchData =  async () => {
  
    const {data} =   await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    
    }) 
    console.log(data?.data,'blog error')

    setData(data?.data  )
   
  console.log(Data,' Data') 
}

fetchData()

},[url])


  return (
    <div className="w-full max-w-[300px] p-[10px] bg-white border-white border-2 shadow-lg rounded-lg sticky mb-4">
      <div className="flex flex-col">
        <button 
          className="font-sans w-full bg-buttongreen px-6 py-1 rounded-md text-white self-end"
          onClick={() => {
            navigate("/login");
            window.scrollTo(0, 0);
          }}
          >
          Donate
        </button>
      </div>
      <div className="w-full h-5 rounded-2xl bg-orange overflow-hidden my-3">
        <div className="w-2/3 h-full bg-greendark"></div>
      </div>
      <div className="pt-3 pb-[3%]">
        <h2 className="text-greenshade text-[1.5rem]">List of Donors</h2>
      </div>
      <div className="pb-[20px] flex gap-1 h-[8rem] overflow-y-auto">
        <div className="w-full cursor-pointer">
          {
            Data?.map(x =>  (
              <div key={x.id} className="text-greenshade">
              <h5 className="font-sans font-bold text-lg">{x.attributes?.firstName} {x.attributes?.lastName} {x.attributes?.amount} $</h5>
              {/* <p className="font-sans font-semibold w-full">{x.attributes?.amount} $</p> */}
            </div>
            ))
          }
        
        </div>
        
      </div>
    </div>
  );
};

export default DonorList;
