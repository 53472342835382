import React, { useEffect } from "react";
import back from "../../assets/Donation section/SVG/home page donation .png";
import { motion } from "framer-motion";
import boxd1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxd2 from "../../assets/Headers/SVG/Asset_3.svg";
const cardVariants = {
  offscreen: {
    y: 200,
  },
  onscreen: {
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};
const Donation = () => {
  useEffect(() => {});
  return (
    <div className="flex flex-wrap w-full  " id="donation">
      <div className="flex w-full ">
        <img 
        className="lg:absolute lg:h-full lg:left-[46%] md:absolute md:h-[85%] md:left-[30%] absolute h-[85%] left-[0%] lg:pt-0 md:pt-0 pt-32" 
        alt="bake" 
        src={back} 
        />
        <div className="w-11/12 m-auto md:mb-32 mb-10 flex flex-col gap-1 items-left text-center py-10 relative">
          <div className="flex mt-10 z-10 flex-col relative text-center">
        <motion.div className="flex mt-0 z-10 flex-col w-[25%] h-[8%]">
  {/* First motion image */}
  <motion.img
    initial={{
      x: 50,
      y: 50,
      opacity: 0,
    }}
    whileInView={{
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        type: "easy",
      },
    }}
    className="absolute -z-10 lg:bottom-[55%] lg:left-[4%] lg:h-[40%] md:bottom-[55%] md:left-[4%] md:h-[40%] bottom-[62%] left-[9.2%] h-[30%] "
    alt="box"
    src={boxd2}
  />
  
  {/* Second motion image */}
  <motion.img
    initial={{
      x: -50,
      y: -50,
      opacity: 0,
    }}
    whileInView={{
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        type: "easy",
      },
    }}
    className="absolute -z-10 lg:bottom-[75%] lg:left-[3%] lg:h-[40%] md:bottom-[75%] md:left-[3%] md:h-[40%] bottom-[75%] left-[7.5%] h-[30%]"
    alt="box"
    src={boxd2}
  />
  
  {/* Third motion image */}
  <motion.img
    initial={{
      x: -50,
      y: 50,
      opacity: 0,
    }}
    whileInView={{
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        type: "easy",
      },
    }}
    className="absolute -z-10 lg:bottom-[44%] lg:left-[1.7%] lg:h-[40%] md:bottom-[45%] md:left-[1.5%] md:h-[40%] bottom-[52%] left-[5.5%] h-[30%] "
    alt="box"
    src={boxd1}
  />
</motion.div>
{/* </div> */}

          <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem] text-greenshade w-[100%] md:w-[50%] ml-[-3%] sm:l-[25%] ">
            Make Donation
          </p>
          <motion.p
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            variants={cardVariants}
            className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] mb-2 text-green md:mb-5 capitalize w-[100%] md:w-[50%] ml-[-5%] "
          >
            Be a part of what we achived so far
          </motion.p>
          </div>
          <div className="flex w-full gap-10  relative py-[5rem]">
            <motion.div
              initial={{
                x: -400,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                x: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              className="lg:h-[20rem] lg:w-[38rem] md:h-[16rem] md:w-[25rem] h-[15rem] w-[20rem] bg-green rounded-3xl lg:p-10 md:p-5 p-3 text-left text-white lg:pt-0 md:pt-0 pt-32"
            >
              <p className="font-roboto font-bold md:text-[1.3rem] text-[1.1rem] mb-3 md:mb-10">
                PLACE YOUE DONATION TODAY
              </p>
              <div className="md:w-9/12 w-full mb-6 border-2 border-white rounded-xl p-3"></div>
              <div className="md:w-6/12 w-9/12 mb-6 flex gap-2">
                <div className="bg-white rounded-xl p-3 w-3/12"></div>
                <div className="bg-white rounded-xl p-3 w-3/12"></div>
                <div className="bg-white rounded-xl p-3 w-6/12"></div>
              </div>
              <div className="md:w-6/12 w-9/12  mb-6 border-2 border-white rounded-xl p-3"></div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="w-3/4 m-auto h-1 bg-black"></div>
    </div>
  );
};

export default Donation;
