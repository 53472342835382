import React from 'react';

const IoannisTsaftaridis = ({  }) => {
  // Your form logic and JSX go here

  return (
    <div className="p-20">
        <p>
        Meet the extraordinary Metropolitan of Zambia and Malawi, Ioannis Tsaftaridis, a man whose journey is as captivating 
        as his commitment to service. Born Ioannis Tsaftaridis in 1969 on the enchanting island of Zakynthos, Greece, he hails 
        from the rich lineage of Sevasteia/Sivas in Pontus Anatólia Central.
        </p>
        <p>
        His Eminence embarked on his educational journey in his hometown, culminating in the pursuit of priesthood at the School 
        of Theology, University of Athens, where he graduated with distinction. In 2010, he earned a Master's degree from the Greek 
        and Latin Studies Faculty of Philosophy at the University of Johannesburg. Currently, he is immersed in crafting his doctoral 
        thesis for the same esteemed institution.
        </p>
        <p>
        The spiritual odyssey of His Eminence began when he was ordained as a Deacon on November 21, 1993, at the Monastery of Yperagathou 
        on the island of Zakynthos, under the jurisdiction of the Monastery of Mount Sinai. This sacred journey continued with his 
        ordination as a Presbyter on December 17 of the same year at the Church of St. Irene of Aeolou Street in Athens, guided by the 
        then Bishop of Dodoni, Archimandrite Chrysostom. In 1998, in Zambia, he achieved the rank of Archimandrite, ordained by the late 
        Pope and Patriarch of Alexandria, Petros VII.
        </p>
        <p>
        Since 1993, His Eminence has dedicated himself to missionary work under the Ancient Patriarchate of Alexandria, actively 
        involved in communities in Zimbabwe, Zambia, Cairo, and South Africa. His influence extends beyond spiritual realms as the 
        intermediary between the DSO (Interparliamentary Assembly on Orthodoxy) and the Panafrican Parliament (PAP). Since 2005, 
        he has been the esteemed representative of His Beatitude Pope and Patriarch Theodore II in the PAP.
        </p>
        <p>
        In a pivotal moment on October 7, 2011, he was elected Bishop of Mozambique, a position that marked a significant chapter 
        in his divine journey. The ordination as Bishop took place on the 17th of the same month, officiated by His Beatitude Pope 
        and Patriarch Theodore II. His unwavering dedication led to his election as Bishop of Zambia and Malawi on November 24, 2015, 
        with the enthronement ceremony held on February 21, 2016.
        </p>
        <p>
        His Eminence Ioannis Tsaftaridis embodies a fusion of scholarship, spiritual devotion, and diplomatic prowess, making his 
        biography a testament to a life of purpose and service.
        </p>
    </div>
  );
};

export default IoannisTsaftaridis;