import React, { useEffect, useState } from "react";
import ProgressBar from "../../components/ProgressBar";
import { motion } from "framer-motion";
import ProjectReport from "./projectReport";
import asset1 from "../../assets/Constantine Project Header copy.png";
import axios from "axios";
import { Link } from "react-router-dom";
import NextProject from "./NextProject";

export default function Project() {
  const [Data, setData] = useState(null);
  const url = process.env.REACT_APP_API_URL + `/projects?populate=*`;
  const headers = {
    Authorization: "bearer" + process.env.REACT_APP_API_TOKEN,
  };

  console.log(url, "url");

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      console.log(data?.data, "blog error");

      setData(data?.data);

      console.log(Data, " Data");
    };

    fetchData();
  }, [url]);

  return (
    <div>
      <div
        className="relative lg:h-[52rem] md:h-[35rem] h-[18rem] w-full overflow-hidden flex items-end"
        id="header">
        <img
          src={asset1}
          alt="header"
          className="absolute inset-0 -z-10 h-auto w-full object-cover object-right md:object-center md:h"
        />
      </div>
      <>
        <div>
          <h1 className="bg-darkgren lg:text-5xl md:text-5xl text-3xl text-white text-center pl-10 pt-32 pr-10 pb-1 ">
            Unfinished Projects
          </h1>
          <h1 className="bg-darkgren text-2xl text-white text-center">
            Currently in Progress Projects
          </h1>
          <h1 className="bg-darkgren lg:text-4xl md:text-4xl text-2xl text-white p-10">
            Latest Projects
          </h1>
          {Data?.filter((g) => g.attributes?.status === "unfinished").map(
            (g) => (
              <div className=" bg-darkgren p-3" key={g.id}>
                <div className="flex flex-row md:flex-row w-[100%]">
                  <div className="flex h-auto md:p-8 rounded-full w-[30%]">
                    <motion.div
                      className=" rounded-full lg:h-[100%] lg:w-[100%] md:h-[70%] md:w-[100%] h-[20%] w-[100%] bg-white flex items-center justify-center "
                      initial={{
                        x: 400,
                        opacity: 0,
                      }}
                      viewport={{ once: true }}
                      whileInView={{
                        x: 0,
                        scale: 1,
                        opacity: 1,
                        transition: {
                          duration: 2,
                          bounce: 2,
                          type: "easy",
                        },
                      }}>
                      <img
                        src={process.env.REACT_APP_API_IMAGE_URL + g.attributes?.left_img_1?.data[0].attributes?.url
                        }
                        alt="ww"
                        className="w-[100%] h-[100%] rounded-full object-cover"
                      />
                    </motion.div>
                  </div>
                  <motion.div
                    className="md:flex-grow text-white p-4 lg:w-[70%] md:w-[70%] w-[1%]"
                    initial={{
                      x: -400,
                      opacity: 0,
                    }}
                    viewport={{ once: true }}
                    whileInView={{
                      x: 0,
                      opacity: 1,
                      scale: 0.98,
                      transition: {
                        duration: 2,
                        bounce: 2,
                        type: "easy",
                      },
                    }}>
                    <div className="flex flex-row w-full sm:w-3/4 p-2 ml-8 mr-8 sm:ml-0 sm:mr-0 sm:order-2">
                      <div className="flex justify-start mb-8">
                        <h1 className="lg:w-2/3 md:w-2/3 w-[10%] text-left sm:text-left text-1xl mt-[3rem] flex flex-row">
                          {g.attributes?.name}
                        </h1>
                      </div>

                      <div className="flex ">
                        <p className="text-center sm:text-left line-clamp-3">
                          {g.attributes?.left_desc_1}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 ">
                      <div className="flex flex-col justify-center mb-3">
                        <h5>Progress</h5>
                        <ProgressBar progress={g.attributes?.progress} />
                      </div>
                      <div className="flex justify-center items-end">
                        <div className="bg-buttongreen hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">
                          <Link
                            preventScrollReset={true}
                            to={`/projects/${g.id}`}
                            onClick={() => window.scrollTo(0, 0)}>
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            )
          )}
        </div>

        <br />

        <div>
          <h1 className=" bg-white text-5xl text-darkgren text-center pl-10 pt-10 pr-10 pb-1 ">
            Finished Projects
          </h1>
          {Data?.filter((g) => g.attributes?.status === "finished").map((g) => (
            <div className="mb-0 mt-0 bg-white" key={g.id}>
              <div className="flex flex-row md:flex-row w-[100%]">
                <div className="flex h-auto md:p-8   rounded-full w-[30%]">
                  <motion.div
                    className=" rounded-full lg:h-[100%] lg:w-[100%] md:h-[70%] md:w-[100%] h-[20%] w-[100%] bg-gray flex items-center justify-center overflow-hidden"
                    initial={{
                      x: 400,
                      opacity: 0,
                    }}
                    viewport={{ once: true }}
                    whileInView={{
                      x: 0,
                      scale: 1,
                      opacity: 1,
                      transition: {
                        duration: 2,
                        bounce: 2,
                        type: "easy",
                      },
                    }}>
                    <img
                      src={
                        process.env.REACT_APP_API_IMAGE_URL +
                        g.attributes?.left_img_1?.data[0].attributes?.url
                      }
                      alt=""
                      className="w-[100%] h-[100%] object-cover rounded-full"
                    />
                  </motion.div>
                </div>
                <motion.div
                  className="md:flex-grow text-white p-4 lg:w-[70%] md:w-[70%] w-[70%]"
                  initial={{
                    x: -400,
                    opacity: 0,
                  }}
                  viewport={{ once: true }}
                  whileInView={{
                    x: 0,
                    opacity: 1,
                    scale: 0.98,
                    transition: {
                      duration: 2,
                      bounce: 2,
                      type: "easy",
                    },
                  }}>
                    
                  <div className="flex flex-col w-full sm:w-3/4 p-2 ml-0 mr-8 sm:ml-0 sm:mr-0 sm:order-2">
                  
                    <div className="flex justify-start mb-8">
                      <h1 className="w-2/3 text-left sm:text-left text-2xl mt-[3rem] text-darkgren">
                        {g.attributes?.name}
                      </h1>
                    </div>

                    <div className="flex ">
                      <p className="w-7/10 text-center sm:text-left text-darkgren line-clamp-3">
                        {g.attributes?.left_desc_1}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 ">
                    <div className="flex flex-col justify-center text-darkgren mb-3">
                      <h5>Progress</h5>
                      <ProgressBar progress={g.attributes?.progress} />
                    </div>
                    <div className="flex justify-center items-end">
                      <div className="bg-buttongreen hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">
                        <Link
                          preventScrollReset={true}
                          to={`/projects/${g.id}`}
                          onClick={() => window.scrollTo(0, 0)}>
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          ))}
        </div>

        <ProjectReport />
      </>
      {/* <ProjectDetail Data={Data} /> */}

    </div>
  );
}
