import * as React from "react";
import header from "../assets/our Team  Header copy 2.png";
import one from "../assets/Designer1.jpg";
import two from "../assets/Designer.png";
import three from "../assets/Designer5.png";
import four from "../assets/web mEMBER pROFILE pICTUER.png";
import five from "../assets/PR - MANAGER.png";
import OneBio from "../components/OneBio"
import GeorgeBio from "../components/GeorgeBio"
import ThreeBio from "../components/ThreeBio"
import IoannisTsaftaridis from "../components/IoannisTsaftaridis";
import { useState } from "react";
import Model from "react-modal";
import { motion } from "framer-motion";

const OurTeam = () => {

    const [isVisible1, setVisible1] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [isLouay, setLouay] = useState(false);
    const [isIoannis, setIoannis] = useState(false);
  return (
    <>
        <img
        src={header}
        alt="header"
        className="w-full object-cover object-right md:object-center"
        />

    <div className="bg-white flex flex-col items-stretch pl-11 pr-20 py-11 max-md:px-5">
        <div className="mr-11 max-md:max-w-full max-md:mr-2.5">
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">

            <div className="flex flex-col items-stretch w-[43%] ml-5 max-md:w-full max-md:ml-0">
                {/* <img
                loading="lazy"
                src={two}
                className="rounded-3xl aspect-square object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-10"
                /> */}
                                        <motion.img
                          viewport={{ once: true }}
                          initial={{
                            x: 0,
                            opacity: 0,
                            scale: 0,
                          }}
                          whileInView={{
                            x: 0,
                            scale: 1,
                            opacity: 1,
                            transition: {
                              duration: 0.5,
                              bounce: 0.1,
                              delay: 0.6,
                              type: "easy",
                            },
                          }}
                          src={two}
                          alt="logo"
                          className="rounded-3xl aspect-square object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-10"
                        />
                    <div>
                                <button onClick={() => setVisible(true)} className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded">
                                    Read About | Sir George Constantine
                                </button>
                                <div>
                        <Model
                          style={{
                            zIndex: 50,
                          }}
                          isOpen={isVisible}
                          onRequestClose={() => setVisible(false)}
                        >
                          <GeorgeBio />
                        </Model>
                        </div>  
                    </div>
            </div>
              
            <div className="flex flex-col items-stretch w-[28%] max-md:w-full max-md:ml-0">
                {/* <img
                loading="lazy"
                src={one}
                className="aspect-[1.01] object-contain object-center w-[317px] overflow-hidden shrink-0 max-w-full grow mt-40 max-md:mt-10"
                /> */}
                        <motion.img
                          viewport={{ once: true }}
                          initial={{
                            x: 0,
                            opacity: 0,
                            scale: 0,
                          }}
                          whileInView={{
                            x: 0,
                            scale: 1,
                            opacity: 1,
                            transition: {
                              duration: 0.5,
                              bounce: 0.1,
                              delay: 1,
                              type: "easy",
                            },
                          }}
                          src={one}
                          alt="logo"
                          className="aspect-[1.01] object-contain object-center w-[317px] overflow-hidden shrink-0 max-w-full grow mt-40 max-md:mt-10"
                        />
                                        <div>
                                <button onClick={() => setVisible1(true)} className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded">
                                    Read About | Archbishop Eminence Daniel
                                </button>
                                <div>
                        <Model
                          style={{
                            zIndex: 50,
                          }}
                          isOpen={isVisible1}
                          onRequestClose={() => setVisible1(false)}
                        >
                          <OneBio />
                        </Model>
                        </div>  
                    </div>
            </div>

            

            <div className="flex flex-col items-stretch w-[29%] ml-5 max-md:w-full max-md:ml-0">
                {/* <img
                loading="lazy"
                src={three}
                className="aspect-[1.03] object-contain object-center w-full overflow-hidden grow max-w-[340px] mt-40 max-md:mt-10"
                /> */}
                        <motion.img
                          viewport={{ once: true }}
                          initial={{
                            x: 0,
                            opacity: 0,
                            scale: 0,
                          }}
                          whileInView={{
                            x: 0,
                            scale: 1,
                            opacity: 1,
                            transition: {
                              duration: 0.5,
                              bounce: 0.1,
                              delay: 1,
                              type: "easy",
                            },
                          }}
                          src={three}
                          alt="logo"
                          className="aspect-[1.03] object-contain object-center w-full overflow-hidden grow max-w-[340px] mt-40 max-md:mt-10"
                        />                
                                        <div>
                                <button onClick={() => setLouay(true)} className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded">
                                    Read About | Mr. Louay Qussibaty
                                </button>
                                <div>
                        <Model
                          style={{
                            zIndex: 50,
                          }}
                          isOpen={isLouay}
                          onRequestClose={() => setLouay(false)}
                        >
                          <ThreeBio />
                        </Model>
                        </div>  
                    </div>
            </div>
            </div>
        </div>
        <div className="self-center w-[845px] max-w-full mt-24 mb-9 max-md:mt-10">
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-[54%] max-md:w-full max-md:ml-0">
                {/* <img
                loading="lazy"
                src={four}
                className="aspect-square object-contain object-center w-full overflow-hidden max-w-[327px] max-md:mt-10"
                /> */}
                        <motion.img
                          viewport={{ once: true }}
                          initial={{
                            x: 0,
                            opacity: 0,
                            scale: 0,
                          }}
                          whileInView={{
                            x: 0,
                            scale: 1,
                            opacity: 1,
                            transition: {
                              duration: 0.5,
                              bounce: 0.1,
                              delay: 1,
                              type: "easy",
                            },
                          }}
                          src={four}
                          alt="logo"
                          className="aspect-square object-contain object-center w-full overflow-hidden max-w-[327px] max-md:mt-10"
                        />                
                                <button onClick={() => setIoannis(true)} className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded">
                                    Read About | Ioannis Tsaftaridis
                                </button>
                                <div>
                        <Model
                          style={{
                            zIndex: 50,
                          }}
                          isOpen={isIoannis}
                          onRequestClose={() => setIoannis(false)}
                        >
                          <IoannisTsaftaridis />
                        </Model>
                        </div>
            </div>
            <div className="flex flex-col items-stretch w-[46%] ml-5 max-md:w-full max-md:ml-0">
                {/* <img
                loading="lazy"
                src={five}
                className="aspect-[1.11] object-contain object-center w-full overflow-hidden grow max-w-[297px] max-md:mt-10"
                /> */}
                        <motion.img
                          viewport={{ once: true }}
                          initial={{
                            x: 0,
                            opacity: 0,
                            scale: 0,
                          }}
                          whileInView={{
                            x: 0,
                            scale: 1,
                            opacity: 1,
                            transition: {
                              duration: 0.5,
                              bounce: 0.1,
                              delay: 1,
                              type: "easy",
                            },
                          }}
                          src={five}
                          alt="logo"
                          className="aspect-[1.11] object-contain object-center w-full overflow-hidden grow max-w-[297px] max-md:mt-10"
                        />                
                                <button className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded">
                                    Read About | John Doe
                                </button>
                                {/* <div>
                        <Model
                          style={{
                            zIndex: 50,
                          }}
                          isOpen={isLouay}
                          onRequestClose={() => setLouay(false)}
                        >
                          <ThreeBio />
                        </Model>
                        </div> */}
                                
            </div>
            </div>
        </div>
        </div>
        </>
  );
};

export default OurTeam;

