import { useNavigate } from "react-router-dom";
import { url } from "../../data";

const Campain = ({ Data }) => {
  console.log(Data ,'Data')
  const navigate = useNavigate();

  return (
    <div>
      {
        Data?.map(x => (
          <div className="sm:flex  gap-5 p-2  pb-2 md:pb-10 lg:ml-6">
          <div className="w-1/2 ">
            <img
              className="rounded-2xl h-100"
              src={process.env.REACT_APP_API_IMAGE_URL + x?.attributes?.imageURL?.data[0].attributes?.url}

              alt="campain"
            />
          </div>
          <div className="sm:w-fit ">
            <h1 className="text-2xl title-font text-white m-3">{x?.attributes?.name}</h1>
            <p className="w-7/10 text-center sm:text-left line-clamp-5 text-white">{x?.attributes?.description}</p>
            <div className="flex justify-end">
              <button
                className=" bg-buttongreen text-white rounded-xl p-3 md:mt-4 mt-2 md:mb-4"
                onClick={() => {
                  navigate("/campaings/" + x.id);
                  window.scrollTo(0, 0);
                }}
              >
                Read More
              </button>
            </div>
          </div>
        </div>
        ))
      }
    </div>

  );
};
export default Campain;
