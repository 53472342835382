import React, { useState } from "react";
import DontationBacground from "../../assets/Constantine_Donate_rm.png";
//import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Donation = () => {
  const navigate = useNavigate();
  const [donation, setDonation] = useState(100);
  const handleRangeChange = (event) => {
    setDonation(event.target.value);
  };

  return (
<div className="flex flex-col lg:flex-row overflow-hidden">


  {/* Form div on the left */}
  <div className="relative z-10 bg-greenshade rounded-3xl lg:ml-8 w-full lg:w-[35%] h-72 md:h-96 sm:h-80 lg:self-center flex flex-col justify-center">
    <p className="text-white font-sans font-semibold text-7xl pl-[3rem] pb-[3rem] border-none focus:outline-none">
      {donation}$
    </p>
    <input
      type="range"
      className="cursor-pointer appearance-none w-4/5 mx-auto h-2 accent-orange self-center"
      min="50"
      max="10000"
      value={donation}
      onChange={handleRangeChange}
    />
    <button 
    className="font-sans bg-buttongreen py-2 rounded-md w-[35%] lg:w-[20%] text-white font-semibold absolute bottom-[4%] right-[4%]"
    onClick={
      () => {
        navigate("/login");
        window.scrollTo(0, 0);
      }
    }
    >
      Donate
    </button>
  </div>
    {/* Image */}
    <img
    src={DontationBacground}
    alt="donation"
    className="w-[40%] 4-[50%] ml-48"
  />
</div>
    
  );
};

export default Donation;
