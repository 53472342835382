import React, { useState } from 'react';
import axios from 'axios';
import hmobile from "../../assets/Contact 4 become Ambassdor copy 2.png";

const BecomeAMember = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });

  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      data: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email
      }
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscribers`, payload, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200 || response.status === 201) {
        console.log('Form submitted successfully:', response.data);
        setFormStatus('success');
      } else {
        console.error('Failed to submit form:', response.statusText);
        setFormStatus('error');
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error in setting up the request:', error.message);
      }
      setFormStatus('error');
    }
  };

  return (
    <div className="relative flex justify-center align-center items-center bg-newgreen rounded-[1rem] my-5 mx-5">
      <div className="flex flex-col bg-gradient-radial  w-[90%] h-[50%] m-[2rem] ">
        <h1 className="text-center text-5xl text-white mt-[2rem]">
          Become A Member
        </h1>
        <div className="flex rounded-[50px] flex-col m-[4rem] bg-gradient-radial from-green to-greenshade">
          <div className="flex flex-col w-[100%] sm:flex-row md:flex-row">
            <div className='p-20 '>                              
            <div className="mx-auto my-4 w-96 ">
      {formStatus === 'success' && (
        <div className="mb-4 text-white pl-10">
          Application submitted successfully!
        </div>
      )}
      {formStatus === 'error' && (
        <div className="mb-4 text-red-600">
          There was an error submitting the form. Please try again.
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <label htmlFor="firstName" className="block text-darkgren text-sm font-bold mb-2 text-left">First Name:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgren leading-tight focus:outline-none focus:shadow-outline" 
          type="text" 
          name="firstName" 
          id="firstName" 
          value={formData.firstName} 
          onChange={handleChange} 
        /> <br />
        <label htmlFor="lastName" className="block text-darkgren text-sm font-bold mb-2 text-left">Last Name:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgren leading-tight focus:outline-none focus:shadow-outline" 
          type="text" 
          name="lastName" 
          id="lastName" 
          value={formData.lastName} 
          onChange={handleChange} 
        /><br />
        <label htmlFor="email" className="block text-darkgren text-sm font-bold mb-2 text-left">Email:</label>
        <input 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-darkgren leading-tight focus:outline-none focus:shadow-outline" 
          type="email" 
          name="email" 
          id="email" 
          value={formData.email} 
          onChange={handleChange} 
        /><br /><br />
        <button className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded" type="submit">Submit</button>
      </form>
    </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex pr-9 pt-10">
        <img src={hmobile} alt="" />
      </div>
    </div>
  );
}

export default BecomeAMember;