import axios from "axios";
import { useContext, useEffect, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { getError } from "../utils";
import { Store } from "../Store";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { url } from "../../data";
import { slugify } from '../../utils/slugify';

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, product: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function ProductScreen() {
  const { state } = useContext(Store);
  const { cart } = state;

  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [{ loading, error, product }, dispatch] = useReducer(reducer, {
    product: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(process.env.REACT_APP_API_URL + `/products/${id}?populate=*&`,

        { headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }}
        );
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    fetchData();
  }, [id]);

  const { dispatch: ctxDispatch } = useContext(Store);

  const {
    cart: { cartItems },
  } = state;

  const addToCartHandler = async () => {
    const existItem = cart.cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(process.env.REACT_APP_API_URL + `/products/${product.id}`,
       { headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }}

    );
    if (data.countInStock < quantity) {
      window.alert("Sorry. Product is out of stock");
      return;
    }
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...product, quantity },
    });
  };
console.log(product,'product')
console.log(cartItems,'cartItems')
  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div className=" rounded-xl  bg-white">
      <div className="flex gap-10 p-8  items-start">
        <div className="w-full md:w-1/3 flex items-center">
          <img
            className="w-full h-[25rem] rounded-lg"
            src={process.env.REACT_APP_API_IMAGE_URL + product?.data[0].attributes?.imageURL?.data.attributes?.url}
            alt={product?.data[0].attributes?.name}
          ></img>
        </div>
        <div className="w-full md:w-5/12 pr-4 border-r-4 border-greenshade">
          <ul className="list-none ">
            <li>
              <div className="text-green pb-2 mb-4 border-b-2 border-green  ">
                <h1 className="text-3xl mb-2">{product?.data[0].attributes?.name}</h1>
              </div>
            </li>
            <li>
              <div className="text-green  mb-4 border-b-2 border-green font-roboto ">
                <span className="text-gray text-sm mr-5 ">Price:</span>
                <span className="text-green text-lg">
                  {product?.data[0].attributes?.price} USD | {product?.data[0].attributes?.gbp} GBP
                </span>
              </div>
            </li>
            <li>
              <div className="text-green  mb-4 pb-2 border-b-2 border-green ">
                <span className="text-gray-600 text-sm font-roboto mr-5">
                  Status:
                </span>
                <span className=" text-white  text-lg">
                  {product?.data[0].attributes?.countInStock > 0 ? (
                    <span className="bg-green p-1 px-2 rounded-lg ">
                      In Stock
                    </span>
                  ) : (
                    <span className="text-red-600 text-lg">Unavailable</span>
                  )}
                </span>
              </div>
            </li>
            <li>
              <div className="mb-4 font-roboto">
                <span className="text-gray text-sm">Description:</span>
                <div>
                  <p className="text-sm">{product?.data[0].attributes?.description}</p>
                </div>
              </div>
            </li>
            {product?.attributes?.countInStock > 0 && (
              <li>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <div className="mb-1">
                      <button
                        onClick={addToCartHandler}
                        className=" bg-blue-100-500 rounded-lg text-white px-4 py-2 mr-2"
                      >
                        Add to Cart
                      </button>
                      <button
                        onClick={() => navigate("/shop/cart")}
                        className="bg-yellow-500 text-black px-4 py-2 rounded-lg"
                      >
                        Order Now
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            )}
            <li>
              <div>
                <button
                  onClick={() => navigate("/shop")}
                  className=" bg-amber-900 text-white px-4 py-2 rounded-lg"
                >
                  Continue Shopping
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div className="w-full md:w-1/4 ">
          <div>
            <h1 className="text-3xl mb-4">Shopping Cart</h1>

              <div>
                {cartItems?.map((item) => (
                  <div key={item.id}>
                    <div className="flex flex-col mb-2">
                      <div>
                        <Link
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                          to={`/shop/product/${item.id}`}
                        >
                          <img
                            src={process.env.REACT_APP_API_IMAGE_URL + item?.attributes?.imageURL?.data[0].attributes?.url}
                            alt={item.name}
                            className="w-16 h-16 rounded-full"
                          />
                        </Link>
                      </div>
                      <div className="ml-4 font-roboto text-[0.9rem] text-green">
                        <div>
                          <Link
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                            to={`/shop/product/${item.id}`}
                          >
                            {item.attributes?.name}
                          </Link>
                        </div>
                        <div className="">X {item?.quantity}</div>
                        <div className="">{item.attributes?.price} USD</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductScreen;
