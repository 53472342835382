import React from 'react';

const Subscription = () => {
  return (
    <div className="text-center self-center w-[80%] text-black bg-white p-[2vw] rounded-lg  left-[20%] ">
      <div className="text-[1.1rem] md:text-[1.3rem] xl:text-[1.5rem]">
        Stay in the loop with the latest updates!
      </div>
      <p className="font-roboto text-[0.6rem] md:text-[0.7rem] xl:text-[0.8rem] text-gray">
        Subscribe now to receive notifications about new events, exciting campaigns, and captivating
        blog posts directly to your inbox. Don't miss out on any important information or engaging
        content.
      </p>
      <div className="font-roboto flex gap-3 mx-[8vw] my-2">
        <input
          type="email"
          placeholder="Enter email address"
          className="pl-1 w-[20rem] outline-none focus:outline-none border-2 border-gray rounded-lg"
        />
        <div className="px-3 py-1 rounded-lg text-white bg-buttongreen">Subscribe</div>
      </div>
      <p className="font-roboto text-[0.6rem] md:text-[0.7rem] xl:text-[0.8rem] text-gray">
        Join our subscription list today and be the first to know!
      </p>
    </div>
  );
};

export default Subscription;
