import React from "react";
import Header from "./Header";
import About from "./About";
import Blog from "./Blog";
import Project from "./Project";
import Report from "./Report";
import Charity from "./Charity";
import Shop from "./Shop";
import Partners from "./Partners";
import Donation from "./Donation";
import Popup from "./Popup"; // Import the Popup component

const Home = () => {
  return (
    <div className="sm: w-full md:w-full ld:w-full overflow-hidden">
      <Header />
      <About /> 
      <Blog />
      <Project />
      <Report />
      <Donation />
      <Charity />
      <Shop />
      <Partners />
      <Popup />
    </div>
  );
};

export default Home;
