// import React, { useEffect, useState } from "react";
// import { motion } from "framer-motion";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import { SampleNextArrow, SamplePrevArrow } from "../../components/SliderArow";
// import boxp1 from "../../assets/Headers/SVG/Asset_2.svg";
// import boxp2 from "../../assets/Headers/SVG/Asset_3.svg";
// import { useNavigate } from "react-router-dom";

// const Charity = () => {
//   const navigate = useNavigate();
//   const [Data, setData] = useState(null);
//   const [SlideToShow, setSlideToShow] = useState(
//     // window.matchMedia("(max-width: 139px)").matches ? 1 : 3
//   );

//   const url = process.env.REACT_APP_API_URL + `/events?populate=*`;

//   useEffect(() => {
//     const fetchData = async () => {
//       const { data } = await axios({
//         method: "get",
//         url: url,
//         headers: {
//           Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
//         },
//       });
//       setData(data?.data);
//     };

//     fetchData();
//   }, [url]);

//   var settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: SlideToShow,
//     slidesToScroll: 1,
//     className: "campaings w-inherit",
//     prevArrow: <SamplePrevArrow />,
//     nextArrow: <SampleNextArrow />,
//     // rows: 1,
//   };

//   return (
//     <div className="flex mt-0 z-10 flex-col relative text-center ">
//       {/* <p className="md:text-[3.5rem] text-[2rem] text-white">Upcoming Events</p>
//       <p className="md:text-[3.5rem] text-[2rem] text-white">The Events to Engaging And Have Fun</p> */}

//       <div className="flex mt-10 z-10 flex-col relative text-center">
//           <motion.div className="flex mt-0 z-10 flex-col w-[25%] h-[8%]">
//             {/* First motion image */}
//             <motion.img
//               initial={{
//                 x: 50,
//                 y: 50,
//                 opacity: 0,
//               }}
//               whileInView={{
//                 x: 0,
//                 y: 0,
//                 opacity: 1,
//                 transition: {
//                   duration: 0.7,
//                   type: "easy",
//                 },
//               }}
//               className="absolute -z-10 lg:bottom-[65%] lg:left-[18%] lg:h-[40%] md:bottom-[65%] md:left-[18%] md:h-[40%] bottom-[78%] left-[14%] h-[30%]"
//               alt="box"
//               src={boxp2}
//             />

//             {/* Second motion image */}
//             <motion.img
//               initial={{
//                 x: -50,
//                 y: -50,
//                 opacity: 0,
//               }}
//               whileInView={{
//                 x: 0,
//                 y: 0,
//                 opacity: 1,
//                 transition: {
//                   duration: 0.7,
//                   type: "easy",
//                 },
//               }}
//               className="absolute -z-10 lg:bottom-[85%] lg:left-[17%] lg:h-[40%] md:bottom-[85%] md:left-[17%] md:h-[40%] bottom-[90%] left-[12%] h-[30%]"
//               alt="box"
//               src={boxp2}
//             />

//             {/* Third motion image */}
//             <motion.img
//               initial={{
//                 x: -50,
//                 y: 50,
//                 opacity: 0,
//               }}
//               whileInView={{
//                 x: 0,
//                 y: 0,
//                 opacity: 1,
//                 transition: {
//                   duration: 0.7,
//                   type: "easy",
//                 },
//               }}
//               className="absolute -z-10 lg:bottom-[55%] lg:left-[15.8%] lg:h-[40%] md:bottom-[55%] md:left-[15.7%] md:h-[40%] bottom-[68%] left-[10%] h-[30%]"
//               alt="box"
//               src={boxp1}
//             />
//           </motion.div>

//           <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem] text-greenshade">
//             Working on Charity
//           </p>
//           <motion.p
//             initial={{
//               y: 10,
//               scale: 0,
//               opacity: 0,
//             }}
//             whileInView={{
//               y: 0,
//               scale: 1,
//               opacity: 1,
//               transition: {
//                 duration: 0.8,
//                 bounce: 1,
//                 type: "easy",
//                 delay: 0.5,
//               },
//             }}
//             viewport={{ once: true }}
//             className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] mb-5 text-green"
//           >
//             Event
//           </motion.p>
//         </div>

//       <div
//         className="font-roboto w-full mt-5 md:mt-20"
//         style={{
//           textAlign: "-webkit-center",
//         }}
//       >
//         <Slider {...settings}>
//               {Data?.map((event) => (
//                 <div className="h-[28rem]" key={event.id}>
//                   <motion.div
//                     viewport={{ once: true }}
//                     className="m-auto relative bg-white rounded-[3rem] md:h-[17rem] h-[9rem] md:mx-10 mx-1 md:hover:mx-7 hover:mx-2 md:w-[15rem] w-[11rem] md:hover:h-[20rem] hover:h-[11.5rem] md:hover:w-[18rem] hover:w-[12rem] group"
//                   >
//                     <img
//                       alt="eventImage"
//                       src={process.env.REACT_APP_API_IMAGE_URL + event.attributes?.imageURL?.data[0].attributes?.url}
//                       // src={process.env.REACT_APP_API_IMAGE_URL + x?.attributes?.imageURL?.data[0].attributes?.url}
//                       className="w-full h-full object-cover rounded-[3rem]"
//                     />
//                     <div className="absolute top-[90%] left-[50%] -translate-x-[50%] w-11/12 m-auto p-3 bg-white rounded-[1rem] drop-shadow-[0_7px_7px_rgba(0,0,0,0.3)] text-left -translate-y-10">
//                       <p className="pl-2 mb-2 text-green font-bold">
//                         {event.attributes.name}
//                       </p>

//                       <div className={`group-hover:block block sm:hidden overflow-hidden`}>
//                         <div className="w-full flex justify-start text-white font-bold">
//                                 <button
//                                   href="#"
//                                   className=" mb-3 rounded-xl inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white hover:text-darkgren bg-darkgren hover:bg-white hover:ring-darkgren"
//                                   onClick={() => {
//                                     navigate("/upeventdetail/" + event.id);
//                                     window.scrollTo(0, 0);
//                                   }}
//                                   >
//                                   Join The Event
//                                 </button>
//                         </div>
//                       </div>
//                       {/* <div className="w-full h-11 items-center bg-orange rounded-xl">
                      
//                       </div> */}
//                       <p className=" p-2 items-center bg-greendark text-white rounded-xl">
//                       {event.attributes.start_date}
//                       </p>
//                     </div>
//                   </motion.div>
//                 </div>
//               ))
//           }
//         </Slider>
//       </div>
//       <div className="gap- mt-20 font-sans"></div>
//     </div>
//   );
// };

// export default Charity;

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { SampleNextArrow, SamplePrevArrow } from "../../components/SliderArow";
import boxp1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxp2 from "../../assets/Headers/SVG/Asset_3.svg";
import { useNavigate } from "react-router-dom";

const Charity = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState(null);
  const [SlideToShow, setSlideToShow] = useState(
    window.innerWidth < 768 ? 1 : window.innerWidth < 1024 ? 2 : 3
  );

  const url = process.env.REACT_APP_API_URL + `/events?populate=*`;

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      setData(data?.data);
    };

    fetchData();

    const handleResize = () => {
      setSlideToShow(window.innerWidth < 768 ? 1 : window.innerWidth < 1024 ? 2 : 3);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [url]);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: SlideToShow,
    slidesToScroll: 1,
    className: "campaigns w-inherit",
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  return (
    <div className="flex mt-0 z-10 flex-col relative text-center">
      <div className="flex mt-10 z-10 flex-col relative text-center">
        <motion.div className="flex mt-0 z-10 flex-col w-[25%] h-[8%]">
          {/* First motion image */}
          <motion.img
            initial={{ x: 50, y: 50, opacity: 0 }}
            whileInView={{
              x: 0,
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.7,
                type: "ease",
              },
            }}
            className="absolute -z-10 lg:bottom-[65%] lg:left-[18%] lg:h-[40%] md:bottom-[65%] md:left-[18%] md:h-[40%] bottom-[78%] left-[14%] h-[30%]"
            alt="box"
            src={boxp2}
          />
          {/* Second motion image */}
          <motion.img
            initial={{ x: -50, y: -50, opacity: 0 }}
            whileInView={{
              x: 0,
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.7,
                type: "ease",
              },
            }}
            className="absolute -z-10 lg:bottom-[85%] lg:left-[17%] lg:h-[40%] md:bottom-[85%] md:left-[17%] md:h-[40%] bottom-[90%] left-[12%] h-[30%]"
            alt="box"
            src={boxp2}
          />
          {/* Third motion image */}
          <motion.img
            initial={{ x: -50, y: 50, opacity: 0 }}
            whileInView={{
              x: 0,
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.7,
                type: "ease",
              },
            }}
            className="absolute -z-10 lg:bottom-[55%] lg:left-[15.8%] lg:h-[40%] md:bottom-[55%] md:left-[15.7%] md:h-[40%] bottom-[68%] left-[10%] h-[30%]"
            alt="box"
            src={boxp1}
          />
        </motion.div>

        <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem] text-greenshade">
          Working on Charity
        </p>
        <motion.p
          initial={{ y: 10, scale: 0, opacity: 0 }}
          whileInView={{
            y: 0,
            scale: 1,
            opacity: 1,
            transition: {
              duration: 0.8,
              bounce: 1,
              type: "ease",
              delay: 0.5,
            },
          }}
          viewport={{ once: true }}
          className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] mb-5 text-green"
        >
          Event
        </motion.p>
      </div>

      <div
        className="font-roboto w-full mt-5 md:mt-20"
        style={{ textAlign: "-webkit-center" }}
      >
        <Slider {...settings}>
          {Data?.map((event) => (
            <div className="h-[28rem]" key={event.id}>
              <motion.div
                viewport={{ once: true }}
                className="m-auto relative bg-white rounded-[3rem] md:h-[17rem] h-[9rem] md:mx-10 mx-1 md:hover:mx-7 hover:mx-2 md:w-[15rem] w-[11rem] md:hover:h-[20rem] hover:h-[11.5rem] md:hover:w-[18rem] hover:w-[12rem] group"
              >
                <img
                  alt="eventImage"
                  src={
                    process.env.REACT_APP_API_IMAGE_URL +
                    event.attributes?.imageURL?.data[0].attributes?.url
                  }
                  className="w-full h-full object-cover rounded-[3rem]"
                />
                <div className="absolute top-[90%] left-[50%] -translate-x-[50%] w-11/12 m-auto p-3 bg-white rounded-[1rem] drop-shadow-[0_7px_7px_rgba(0,0,0,0.3)] text-left -translate-y-10">
                  <p className="pl-2 mb-2 text-green font-bold">
                    {event.attributes.name}
                  </p>

                  <div
                    className={`group-hover:block block sm:hidden overflow-hidden`}
                  >
                    <div className="w-full flex justify-start text-white font-bold">
                      <button
                        href="#"
                        className=" mb-3 rounded-xl inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white hover:text-darkgren bg-darkgren hover:bg-white hover:ring-darkgren"
                        onClick={() => {
                          navigate("/upeventdetail/" + event.id);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Join The Event
                      </button>
                    </div>
                  </div>
                  <p className="p-2 items-center bg-greendark text-white rounded-xl">
                    {event.attributes.start_date}
                  </p>
                </div>
              </motion.div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="gap- mt-20 font-sans"></div>
    </div>
  );
};

export default Charity;
