// import React from "react";
// import { useState, useEffect } from "react";
// import FooterLogo from "../assets/FooterElements/SVG/Untitled-1.png";
// import { Link } from "react-router-dom";
// import Subscription from "../components/Subscription";
// import Volunteer from './volunteer';
// //import campaign from "../../src/pages/campaign/Campain";
// import {
//   FaTwitter,
//   FaFacebook,
//   FaLinkedin,
//   FaYoutube,
//   FaEnvelope,
// } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
// import Modal from "./VolunteerPopup";

// const Footer = () => {
//   // popup
//   const [open, setOpen] = useState(false)

// // time stamp code
//   const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
//   useEffect(() => {
//     // Update the current year when the component mounts
//     setCurrentYear(new Date().getFullYear());
//   }, []);

//   return (
//     <div className="w-full font-roboto  text-white  relative ">
//       <div className="h-full w-full absolute overflow-hidden -z-10">
//       <div class="h-full w-full absolute overflow-hidden -z-10 bg-gradient-to-b from-[#0b752a] to-[#00433b]"></div>
//       </div>
//       <div className="flex flex-wrap md:flex-nowrap md:p-2 md:gap-2 lg:p-16 lg:gap-10 p-16 gap-10">
//         <div className="w-full">
//           <img
//             alt="logo"
//             src={FooterLogo}
//             className="lg:h-[7rem] md:h-[5rem] h-[7rem] object-cover mb-7"
//           />
//           <p className="text-[0.8rem]">
//           Constantine African Foundation was established with a
//            goal to advocate for the rights of marginalized and vulnerable populations
//             to access quality education and healthcare in all of Africa.
//           </p>
//         </div>

//         <div className="flex flex-col w-full">
    
//         <div className="flex flex-row w-full">
          
//           <div className="w-full">
//             <p className="mb-4 text-[1.2rem] font-bold ">How We Serve</p>
//             <div className="grid-cols-2 gap-4">
//               <Link
//                 onClick={() => window.scrollTo(0, 0)}
//                 to={`/campaings`} 
//               >
//                 <p>Campaign</p>
//               </Link>
//               <Link
//                                 onClick={() => window.scrollTo(0, 0)}
//                                 to={`/Projects`} 
//               >
//                 <p>Projects</p>
//               </Link>
//             </div>
//           </div>

//           <div className="w-full">
//             <p className="mb-4 text-[1.2rem] font-bold ">Resources</p>
//             <div className=" grid grid-cols-2 gap-4">
//               <Link
//                               onClick={() => window.scrollTo(0, 0)}
//                               to={`/Blogs`} 
//               >
//                 <p>Blogs</p>
//               </Link>
//             </div>
//           </div>

//           <div className="w-full">
//             <p className="mb-4 text-[1.2rem] font-bold ">Get to Know Us</p>
//             <div className="grid-cols-2 gap-4">
//               <Link
//                               onClick={() => window.scrollTo(0, 0)}
//                               to={`/our-team`} 
//               >
//                 <p>Our Team</p>
//               </Link>
//               <Link
//                               onClick={() => window.scrollTo(0, 0)}
//                               to={`/about`} 
//               >
//                 <p>About Us</p>
//               </Link>
//             </div>

//           </div>
//         </div>
//         <div>
//           <Subscription />
//         </div>
//       </div>  
        
//         <div className="w-full">
//           <p className="mb-4 text-[1.2rem] font-bold ">Help Our Cause</p>
//           <div className="grid-cols-2 gap-4">
//             <Link
//                             onClick={() => window.scrollTo(0, 0)}
//                             to={`/Products`} 
//             >
//               <p>Products</p>
//             </Link>
//             <Link
//                             onClick={() => window.scrollTo(0, 0)}
//                             to={`/Careers`} 
//             >
//               <p>Careers</p>
//             </Link>
//             <Link
//                 onClick={() => window.scrollTo(0, 0)}
//                 to={`/Service`} 
//             >
//               <p>Other Service</p>
//             </Link>
//           </div>
//           <br/>
//           <br/>
//           <br/>
          
//           <main className="App">
            
//            <button className="rounded-xl bg-white md:text-2xl text-[0.7rem] px-3 py-1 mt-5 text-green cursor-pointer hover:scale-105" onClick={() => setOpen(true)}>
//               Be Volunteer
//            </button>
//               <Modal open={open} onClose={() => setOpen(false)}>
//                 <div className="text-center w-96">
//                     <Volunteer />
//                   <div className="flex gap-4">
//                     <button
//                       className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
//                       onClick={() => setOpen(false)}
//                     >
//                       Close
//                     </button>
//                   </div>
//                 </div>
//               </Modal>
//             </main>
//         </div>

//         <div className="w-full">
//           <p className="font-Culpa lg:text-[3rem] lg:w-[15rem]  md:text-[2rem] md:w-[8rem] text-[3rem] w-[15rem] text-center mx-auto leading-[1.3] mb-3 ">
//             Together We Change
//           </p>
//           <div className="flex justify-center mb-3">
//             <div className="bg-white text-green font-bold rounded-full px-3 py-1 text-base">
//               Help Our Cause
//             </div>
//           </div>
//           <p className=" text-[.9rem] mb-3 text-center font-bold">
//             Follow Us On{" "}
//           </p>
//           <div className="flex w-full justify-center gap-3">
//                 {/* <FaLinkedin size={34} /> */}
//               <a href="https://www.linkedin.com/company/constantine-foundation/about/?viewAsMember=true">
//                 <FaLinkedin size={34} />
//               </a>
//               <a href="https://www.">
//               <FaXTwitter size={34} />
//               </a>
//               <a href="https://www.facebook.com/profile.php?id=61556522792774">
//               <FaFacebook size={34} />
//               </a>
//               <a href="https://www.">
//               <FaYoutube size={34} />
//               </a>
//               <a href="https://www.">
//               <FaEnvelope size={34} />
//               </a>  
//           </div>
//         </div>
//       </div>
//       <div
//         className=" flex justify-center lg:gap-40 md:gap-20 gap-10 py-6 lg:px-6 md:px-6 px-3 text-[.9rem] "
//         style={{ background: "#024331" }}
//       >
//       {/* <a>
//         <p>Terms of Service</p>
//       </a> */}
//       <Link
//                 onClick={() => window.scrollTo(0, 0)}
//                 to={`/term`} 
//               >
//                 <p>Terms of Service</p>
//               </Link>
//       <a>
//         <p>&copy; {currentYear} Constantine Africa Foundation. All Rights Reserved.</p>
//       </a>
//       {/* <a>
//         <p>Privacy Policy</p>
//       </a> */}
//       <Link
//                 onClick={() => window.scrollTo(0, 0)}
//                 to={`/privacy`} 
//               >
//                 <p>Privacy Policy</p>
//               </Link>
//       </div>
//     </div>
//   );
// };

// export default Footer;


import React, { useState, useEffect } from "react";
import FooterLogo from "../assets/FooterElements/SVG/Untitled-1.png";
import { Link } from "react-router-dom";
import Subscription from "../components/Subscription";
import Volunteer from "./volunteer";
import { FaTwitter, FaFacebook, FaLinkedin, FaYoutube, FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Modal from "./VolunteerPopup";

const Footer: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <div className="w-full font-roboto text-white relative">
      <div className="h-full w-full absolute overflow-hidden -z-10">
        <div className="h-full w-full absolute overflow-hidden -z-10 bg-gradient-to-b from-[#0b752a] to-[#00433b]"></div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap md:p-2 md:gap-2 lg:p-16 lg:gap-10 p-6 gap-6">
        <div className="w-full">
          <img
            alt="logo"
            src={FooterLogo}
            className="lg:h-[7rem] md:h-[5rem] h-[4rem] object-cover mb-7"
          />
          <p className="text-[0.8rem]">
            Constantine African Foundation was established with a
            goal to advocate for the rights of marginalized and vulnerable populations
            to access quality education and healthcare in all of Africa.
          </p>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row w-full">
            <div className="w-full">
              <p className="mb-4 text-[1.2rem] font-bold">How We Serve</p>
              <div className="grid grid-cols-2 gap-4">
                <Link onClick={() => window.scrollTo(0, 0)} to={`/campaings`}>
                  <p>Campaign</p>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to={`/Projects`}>
                  <p>Projects</p>
                </Link>
              </div>
            </div>
            <div className="w-full">
              <p className="mb-4 text-[1.2rem] font-bold">Resources</p>
              <div className="grid grid-cols-2 gap-4">
                <Link onClick={() => window.scrollTo(0, 0)} to={`/Blogs`}>
                  <p>Blogs</p>
                </Link>
              </div>
            </div>
            <div className="w-full">
              <p className="mb-4 text-[1.2rem] font-bold">Get to Know Us</p>
              <div className="grid grid-cols-2 gap-4">
                <Link onClick={() => window.scrollTo(0, 0)} to={`/our-team`}>
                  <p>Our Team</p>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to={`/about`}>
                  <p>About Us</p>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <Subscription />
          </div>
        </div>
        <div className="w-full">
          <p className="mb-4 text-[1.2rem] font-bold">Help Our Cause</p>
          <div className="grid grid-cols-2 gap-4">
            <Link onClick={() => window.scrollTo(0, 0)} to={`/Products`}>
              <p>Products</p>
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to={`/Careers`}>
              <p>Careers</p>
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to={`/Service`}>
              <p>Other Service</p>
            </Link>
          </div>
          <div className="mt-6">
            <button
              className="rounded-xl bg-white md:text-2xl text-[0.7rem] px-3 py-1 mt-5 text-green cursor-pointer hover:scale-105"
              onClick={() => setOpen(true)}
            >
              Be Volunteer
            </button>
            <Modal open={open} onClose={() => setOpen(false)}>
              <div className="text-center w-96">
                <Volunteer />
                <div className="flex gap-4 mt-4">
                  <button
                    className="bg-transparent hover:bg-darkgren text-darkgren font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="w-full">
          <p className="font-Culpa lg:text-[3rem] lg:w-[15rem] md:text-[2rem] md:w-[8rem] text-[2rem] w-[10rem] text-center mx-auto leading-[1.3] mb-3">
            Together We Change
          </p>
          <div className="flex justify-center mb-3">
            <div className="bg-white text-green font-bold rounded-full px-3 py-1 text-base">
              Help Our Cause
            </div>
          </div>
          <p className="text-[.9rem] mb-3 text-center font-bold">
            Follow Us On
          </p>
          <div className="flex w-full justify-center gap-3">
            <a href="https://www.linkedin.com/company/constantine-foundation/about/?viewAsMember=true">
              <FaLinkedin size={34} />
            </a>
            <a href="https://www.">
              <FaXTwitter size={34} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61556522792774">
              <FaFacebook size={34} />
            </a>
            <a href="https://www.">
              <FaYoutube size={34} />
            </a>
            <a href="https://www.">
              <FaEnvelope size={34} />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center lg:gap-40 md:gap-20 gap-10 py-6 lg:px-6 md:px-6 px-3 text-[.9rem]" style={{ background: "#024331" }}>
        <Link onClick={() => window.scrollTo(0, 0)} to={`/term`}>
          <p>Terms of Service</p>
        </Link>
        <p>&copy; {currentYear} Constantine Africa Foundation. All Rights Reserved.</p>
        <Link onClick={() => window.scrollTo(0, 0)} to={`/privacy`}>
          <p>Privacy Policy</p>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
