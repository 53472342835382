import React, { useState } from "react";
import axios from "axios";

const SignUpForm = ({ setIsLogin }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "", // Added confirmPassword to formData
  });

  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setFormStatus("passwordMismatch");
      return;
    }

    const payload = {
      data: {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        console.log("Feedback sent successfully:", response.data);
        setFormStatus("success");
      } else {
        console.error("Failed to submit form:", response.statusText);
        setFormStatus("error");
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response from server:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error in setting up the request:", error.message);
      }
      setFormStatus("error");
    }
  };

  return (
    <div className="bg-darkgreen text-white rounded-2xl shadow-2xl flex flex-col w-full md:w-[40%] items-center max-w-4xl transition duration-1000 ease-in">
      <h2 className="p-3 text-3xl font-bold text-white">
        Constantine Foundation
      </h2>
      <div className="inline-block border-[1px] justify-center w-20 border-white border-solid"></div>
      <h3 className="text-xl font-semibold text-white pt-2">Create Account!</h3>
      <div className="flex space-x-2 m-4 items-center justify-center">
        <div className="socialIcon border-white">
          {formStatus === "success" && (
            <div className="mb-4 text-green">
              You have been registered successfully!
            </div>
          )}
          {formStatus === "error" && (
            <div className="mb-4 text-red-600">
              There was an error on registration. Please try again.
            </div>
          )}
          {formStatus === "passwordMismatch" && (
            <div className="mb-4 text-red-600">
              Passwords do not match. Please try again.
            </div>
          )}
        </div>
      </div>
      <form
        className="flex flex-col items-center justify-center mt-2 w-[80%]"
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          name="name"
          className="text-darkgreen rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          className="text-darkgreen rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          className="text-darkgreen rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
        />
        <input
          type="password"
          name="confirmPassword"
          className="text-darkgreen rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
          placeholder="Confirm Password"
          value={formData.confirmPassword}
          onChange={handleChange}
        />
        <button
          type="submit"
          className="rounded-2xl m-4 text-darkgreen bg-white w-3/5 px-4 py-2 shadow-md hover:text-white hover:bg-darkgreen transition duration-200 ease-in"
        >
          Sign Up
        </button>
      </form>
      <div className="inline-block border-[1px] justify-center w-20 border-white border-solid"></div>
      <p className="text-white mt-4 text-sm">Already have an account?</p>
      <p
        className="text-white mb-4 text-sm font-medium cursor-pointer"
        onClick={() => setIsLogin(true)}
      >
        Sign In to your Account.
      </p>
    </div>
  );
};

export default SignUpForm;
