import { useDispatch, useSelector } from "react-redux";
import Campain from "./singCampain";
import { useEffect } from "react";
import Donors from "../donors";
 import Event from "../../shopandeventsrc/components/Event";
import Subscription from "../../components/Subscription";
import axios from "axios";
import { useState } from "react";

const CampainList = ( ) => {
  const [Data, setData]= useState(null)
  console.log(Data,'CampainList  ')
 
const url = process.env.REACT_APP_API_URL + `/campaigns?populate=*`;
const headers = {
  "Authorization":"bearer" + process.env.REACT_APP_API_TOKEN 
}
console.log(url,'url')
 
useEffect(() => {
  const fetchData =  async () => {
  
    const {data} =   await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    
    }) 
    console.log(data?.data,'blog error')

    setData(data?.data  )
   
  console.log(Data,' Data') 
}

fetchData()

},[url])

  return (
    <div>
      <div className="top-[50%] bg-darkgren">
        <h1 className="bg-darkgren text-5xl text-white text-center pl-10 pt-10 pr-10 pb-1 ">Current Active campaign</h1>
        <div className="md:flex lg:gap-24 gap-10 pt-10 lg:ml-10 lg:mr-10 justify-center">
          <div className="md:w-6/6 w-full md:border-r-2 h-35 border-green pr-2 m-5">
            <div className="md:overflow-y-scroll h-5/6 scrollbar-curved pl-5 md:pr-5">


             
              {Data === "" ? (
                <div className="flex flex-col items-center justify-center h-[25rem]">
                  <div className="w-24 h-24 border-l-2 border-gray-900 rounded-full animate-spin"></div>
                  <div className="pt-[2rem]">Loading...</div>
                </div>
              ) : (
                <>
             
                <Campain  Data={Data}/>

                </>
              )}
            </div>
          </div>
          {/* <Event/> */}
          <Donors />
        </div>
      </div>
    </div>
  );
};

export default CampainList;
