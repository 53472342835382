import React from 'react';

const GeorgeBio = ({ onClose }) => {
  // Your form logic and JSX go here

  return (
    <div className="p-20">
      <h1 className='text-5xl text-center text-darkgren pb-10 py-10'>"Founding Visionary: Bridging Hospitality Expertise and Philanthropy for the Constantine African Foundation"</h1>
        <p>
        As the founder of the Constantine African Foundation, my vision is deeply rooted in the inspiration drawn from the esteemed 
        Patriarch Theodore II of Alexandria, reflecting my commitment as an Orthodox member of the Patriarchate of Alexandria and All Africa.
        Furthermore, my role as the Chief Secretary for the Metropolitan of Aksum has afforded me invaluable insights into leadership and 
        community engagement. With a career spanning over two decades in the hospitality industry, I bring a wealth of experience to the 
        Hospitality Industry. 
        </p>
        <p>
        As a proud member of Lion Club International, Geneva, I've not only undergone transformative leadership development 
        but have also imbibed the profound value of community service. These experiences have ignited in me a fervent desire 
        to utilize my extensive network and skills for a meaningful cause. With a keen focus on the health sector, I am enthusiastic 
        about channeling my passion and expertise towards making substantial contributions to the Constantine African Foundation. 
        Together, let's embark on a journey of empowerment and positive change.
        </p>
        <p>
        Throughout my career, I have demonstrated excellence in hotel operations and quality service, participating in international 
        trade fairs, roadshows, and strategic projects. My expertise extends to eco and cultural tourism, with a focus on sustainable 
        human development. 
        </p>
        <p>
        I am driven by an innate passion for hospitality and a genuine desire to serve others. My dedication to continuous 
        learning and collaboration with industry peers ensures that I stay abreast of international standards. 
        I am confident that my unwavering commitment, diverse skill set, and extensive network position me as an ideal candidate to 
        contribute significantly to the Constantine African Foundation, aligning with its mission and vision for positive change.
        </p>
    </div>
  );
};

export default GeorgeBio;