import React from 'react';

const OneBio = ({ onClose }) => {
  // Your form logic and JSX go here

  return (
    <div className="p-20">
        <p>
        His Eminence Daniel, Archbishop of Aksum, Most Honorable and Exarch of All Ethiopia, Eritrea, Djibouti
        and Somalia, was born in Greece, Europe. He holds a Degree from the Technical Vocational School of
        Zakynthos, Department of Economics and Management, Department of Hotel Βusinesses (2000). After
        his school education, he attended courses at the School of Accountants, at the Higher Educational
        Institution of Messolonghi town (1998). After, he studied at the Higher Ecclesiastical School in Ioannina
        town (2004) and then at the Aristotle University of Thessaloniki, Faculty of Theology, from where he
        received the Degree in Pastoral and Social Theology (2010). He holds a Certificate of Education in the
        subject "Addictions Counseling", from the National and Kapodistrian University of Athens (2011). He
        holds a Master Degree in Social Theology and Christian Culture (Th.M.), in the Faculty of Humanities
        from the Hellenic Open University (2023). 
        </p>
        <p>
        He was tonsured a Monk at the Holy Strofadon and St Dionysios of Zakynthos Monastery, was ordained
        Deacon on 25/12/1997 and Priest on 17/09/2006 by the then Metropolitan Chrysostomos of Zakynthos.
        He served as Preacher in the Metropolis of Zakynthos (2011) and as Abbot of the Holy Monastery of the
        Most Holy Theotokos “Spiliotissa” (2009). He served as a Missionary in the Holy Patriarchate of
        Alexandria and All Africa in its Provinces of Mozambique, South Africa, Zambia and Malawi, Ethiopia,
        Eritrea, Djibouti, Somalia. He was Chancellor of the Holy Metropolis of Zambia and Malawi (2016). He
        was seconded at the instigation of His Beatitude Theodoros II, Pope and Patriarch of Alexandria and All
        Africa to the ancient Patriarchate of Alexandria in Egypt. Called to See of the Patriarchate in Alexandria,
        he served as Parish Priest of the Holy Church of the Annunciation of the Theotokos in Alexandria (2016)
        and from 18/1/2017 as Chief Secretary of the Holy and Sacred Synod. Proposed by His Beatitude and by
        regular votes of the Holy Synod, he was elected on 26/11/2018 as Metropolitan of Aksum in Ethiopia. He
        was ordained Bishop by His Beatitude, at the Holy Monastery of Saint Savva of Alexandria, on December
        5, 2018 and enthroned by His Beatitude on 2/3/2019 at the Cathedral of Saint Frumentios, Addis Ababa.
        He has been living permanently for the last decade in Africa and has developed philanthropic activity in
        many areas as part of his duties.
        </p>
    </div>
  );
};

export default OneBio;