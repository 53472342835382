import React, { useEffect, useReducer, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { SampleNextArrow, SamplePrevArrow } from "../../components/SliderArow";
import axios from "axios";
import { Link } from "react-router-dom";
//import { url } from "../../data";
import boxs1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxs2 from "../../assets/Headers/SVG/Asset_3.svg";
import { motion } from "framer-motion";

const Shop = () => {

  const [Data, setData]= useState(null)

  const url = process.env.REACT_APP_API_URL + `/products?populate=*`;
  const headers = {
    "Authorization":"bearer" + process.env.REACT_APP_API_TOKEN
  }
  console.log(url,'url')

  useEffect(() => {
    const fetchData =  async () => {

      const {data} =   await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },

      })
      console.log(data?.data,'shop error')

      setData(data?.data  )

    console.log(Data,' Data')
  }

  fetchData()

  const handleMediaChange = (e) => {
    setSlideToShow(e.matches ? 1 : 4);
  };

  const mediaQuery = window.matchMedia("(max-width: 816px)");
  mediaQuery.addEventListener("change", handleMediaChange);

  return () => {
    mediaQuery.removeEventListener("change", handleMediaChange);
  };

  },[url])

  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUEST":
        return { ...state, loading: true };
      case "FETCH_SUCCESS":
        return { ...state, products: action.payload, loading: false };
      case "FETCH_FAIL":
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };

  const [SlideToShow, setSlideToShow] = useState(
    window.matchMedia("(max-width: 816px)").matches ? 1 : 4
  );
  // const [{ loading, products }, dispatch] = useReducer(reducer, {
  //   products: [],
  //   loading: true,
  //   error: "",
  // });

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: SlideToShow,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

    return (
      <div className="w-full bg-primary">
        <div className="w-full px-16 m-auto flex flex-col gap-1 items-left text-center py-5 text-white ">
          <div className="flex mt-10 z-10 flex-col relative text-center">
            <motion.div className="flex mt-0 z-10 flex-col w-[25%] h-[8%]">
              {/* First motion image */}
              <motion.img
                initial={{
                  x: 50,
                  y: 50,
                  opacity: 0,
                }}
                whileInView={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.7,
                    type: "easy",
                  },
                }}
                className="absolute -z-10 lg:bottom-[50%] lg:left-[14%] lg:h-[40%] md:bottom-[50%] md:left-[14%] md:h-[40%] bottom-[55%] left-[8.5%] h-[30%]"
                alt="box"
                src={boxs2}
              />

              {/* Second motion image */}
              <motion.img
                initial={{
                  x: -50,
                  y: -50,
                  opacity: 0,
                }}
                whileInView={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.7,
                    type: "easy",
                  },
                }}
                className="absolute -z-10 lg:bottom-[70%] lg:left-[13%] lg:h-[40%] md:bottom-[70%] md:left-[12.5%] md:h-[40%] bottom-[70%] left-[6%] h-[30%]"
                alt="box"
                src={boxs2}
              />

              {/* Third motion image */}
              <motion.img
                initial={{
                  x: -50,
                  y: 50,
                  opacity: 0,
                }}
                whileInView={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.7,
                    type: "easy",
                  },
                }}
                className="absolute -z-10 lg:bottom-[40%] lg:left-[11.8%] lg:h-[40%] md:bottom-[40%] md:left-[11.2%] md:h-[40%] bottom-[48%] left-[2.8%] h-[30%]"
                alt="box"
                src={boxs1}
              />
            </motion.div>

            <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem]">Charity Shop</p>
            <p className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] mb-5">
              present someone with a gift
            </p>
          </div>
          <div className="w-full gap-12 mb-[4rem] items-center ">
            <Slider {...settings}>
                {Data?.map((product) => (
                  <div className="w-full mt-[11rem] px-5" key={product.id}>
                    <div className="relative w-full">
                      <div className="w-[12rem] h-[12rem] bg-gray rounded-xl overflow-hidden absolute bottom-[80%] left-[50%] translate-x-[-50%] ">
                        <img
                          alt="blogImage"
                          src={process.env.REACT_APP_API_IMAGE_URL + product.attributes?.imageURL.data[0].attributes?.url}
                          className="w-full h-full object-cover rounded-[1rem] scale-[1.7]"
                        />
                      </div>
                      <div className="w-full bg-white rounded-[1rem] p-6 pt-[3.5rem] text-black text-left ">
                        <div className="h-[7rem]">
                          <p className="text-[1.2rem] mb-2 font-bold w-[8rem]">
                            {product.attributes.name}
                          </p>
                          <p className="text-sm mb-2 leading-none font-sans max-h-[5rem] overflow-hidden ">
                            {product.attributes.description}
                          </p>
                        </div>

                        <div className="w-full flex justify-center font-sans text-lime font-bold ">
                          <p className="px-3 border-r-2 text-[1.3rem]">
                            {product.attributes.price}
                            <span className="text-[0.9rem]">USD</span>
                          </p>
                          {/* <p className="px-3 text-[1.3rem]">
                            <span className="text-[0.9rem]">GBP</span>
                          </p> */}
                        </div>
                        <div className="w-full flex justify-center text-white font-bold mt-6 ">
                          <Link
                            to={`/shop/product/${product.attributes.slug}`}
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div className="font-sans bg-buttongreen rounded-xl px-2 py-1 text-sm ">
                              Shop now
                              </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  </div>
);
}

export default Shop;
