import React, { useEffect } from "react";
import ContentCard from "../../../components/Card";
import RecentCard from "../../../components/RecentCard";
// import { useDispatch, useSelector } from "react-redux";
// import { url } from "../../../data";
import axios from "axios";
import { useState } from "react";

const Body = () => {
  const [Data, setData]= useState(null)
 
const url = process.env.REACT_APP_API_URL + `/blogs?populate=*`;
const headers = {
  "Authorization":"bearer" + process.env.REACT_APP_API_TOKEN 
}
console.log(url,'url')
 
useEffect(() => {
  const fetchData =  async () => {
  
    const {data} =   await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    
    }) 
    console.log(data?.data,'blog error')

    setData(data?.data  )
   
  console.log(Data,' Data') 
}

fetchData()

},[url])

  return (
    // <div className="flex flex-wrap w-full">
    //   <div className="flex flex-wrap gap-[3rem]  p-[1rem] md:p-[1rem] md:py-[2.5rem] justify-center xl:w-[60%]">
    //       <ContentCard
    //         Data = {Data}
    //       />
    //   </div>
    //   <div className="p-[1rem] flex-wrap xl:w-[40%] inline-block">
    //     <RecentCard   />
    //   </div>
    // </div>
    <div className="flex flex-wrap w-full">
    <div className="flex flex-wrap gap-[3rem]  p-[1rem] md:p-[1rem] md:py-[2.5rem] justify-center xl:w-[60%]">
        <ContentCard
          Data = {Data}
        />
    </div>
    <div className="p-[1rem] flex-wrap xl:w-[40%] inline-block">
      <RecentCard   />
    </div>
  </div>
  );
};

export default Body;
