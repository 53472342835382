import { Route, Routes, useLocation } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
import { Home, ContactUs, BlogList, BlogDetail } from "./pages/Index";
import NavBar from "./components/NavBar";

// import AdminPortal from "./pages/AdminPortal/AdminPortal";
import Campains from "./pages/campaign/Campains";
import CampainDetail from "./pages/campaign/campainDetail";
import Footer from "./components/Footer";

import Project from "./pages/project-page/project";
import ProjectDetail from "./pages/project-page/projectDetail";
import AboutUs from "./pages/about-page/about";
import Services from "../src/components/Services";

import Event from "./shopandeventsrc/components/Event";
// import SignIn from "./pages/SignIp/SignIn";

// import { LogOut, Login } from "./redux/action";

import ShopLayout from "./shopandeventsrc/screens/ShopLayout";
import EventSub from "../src/shopandeventsrc/components/eventsub";
import Careers from "../src/components/Careers";
import OurTeam from "../src/components/OurTeam";
import BecomeAmbasador from "../src/pages/about-page/BecomeAmbasador";
// import BecomeAMember from "../src/pages/about-page/BecomeAMember";
import { GlobalDebug } from "./utils/remove-console";


import DonationPayment from "./components/DonationPay";
// import Payment from "./components/Payment";

import LatestEventDetail from "./shopandeventsrc/components/LatestEventDetail";
import UpEventDetail from "./shopandeventsrc/components/UpEventDetail";

import Auth from "./components/Auth.jsx";

import CookieConsent from './CookieConsent.jsx';
import Privacy from './components/PrivacyPolicy.jsx';
import Term from './components/TermsOfService.jsx';
import Donate from './components/Donater.jsx'
import Payment from "./components/payment.jsx";



function App() {
  const isLogin = useSelector((state) => state.AuthReduce);
  const dispatch = useDispatch();
  const location = useLocation();
  // useEffect(() => {
  //   if (localStorage.getItem("constantineToken")) {
  //     dispatch(Login());
  //   } else {
  //     dispatch(LogOut());
  //   }
  // }, [location.pathname, dispatch]);


  // useEffect(() => {
  //   (process.env.NODE_ENV === "development" ||
  //    process.env.REACT_APP_ENV === "STAGING") &&
  //     GlobalDebug(false);
  // }, []);
  return (
    <div className="App">
      <CookieConsent />
      {location.pathname.split("/")[1] !== "account" &&
        location.pathname.split("/")[1] !== "signin" && <NavBar />}
      <Routes>
        <Route path={"/home"} element={<Home />} />
        <Route path={"/"} element={<Home />} />

        <Route path={"/contact"} element={<ContactUs />} />
        <Route path={"/blogs"} element={<BlogList />} />
        <Route path={"/blogs/:id"} element={<BlogDetail />} />

        <Route path={"/becomeambasador"} element={<BecomeAmbasador />} />
        {/* <Route path={"/BecomeAmbasador"} element={<BecomeAmbasador />} /> */}

        <Route path="/campaings" element={<Campains />} />
        <Route path={"/campaings/:id"} element={<CampainDetail />} />
        <Route path="/campaings/Detail/:id" element={<CampainDetail />}/>
        
        <Route path="/donationpay" element={<DonationPayment />} />
        <Route path="/payment" element={<Payment />} />

        <Route path="/auth" element={<Auth />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/payment" element={<Payment />} />

        <Route path={"/event"} element={<Event />} />
        <Route path="/event/eventsub" element={<EventSub />} />

        <Route path={"/shop/*"} element={<ShopLayout />} />
        <Route path={"/products"} element={<ShopLayout />} />

        <Route path="/projects" element={<Project />} />
        <Route path="/projects/:id" element={<ProjectDetail />} />
        <Route path="/about" element={<AboutUs />} />

        <Route path="/careers" element={<Careers />} />
        <Route path="/Service" element={<Services />} />
        <Route path="/our-team" element={<OurTeam />} />

        <Route path="/latesteventdetail/:id" element={<LatestEventDetail />} />
        <Route path="/upeventdetail/:id" element={<UpEventDetail />} />

        <Route path="/privacy" element={<Privacy />} />
        <Route path="/term" element={<Term />} />

        {/* <Route
          path={"/account/*"}
          element={isLogin ? <AdminPortal /> : <Navigate to={"/signin"} />}
        /> */}
        {/* <Route
          path="/signin"
          element={isLogin ? <Navigate to={"/account"} /> : <SignIn />}
        /> */}
      </Routes>
      {location.pathname.split("/")[1] !== "account" &&
        location.pathname.split("/")[1] !== "signin" && <Footer />}
    </div>
  );
}

export default App;
