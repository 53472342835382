import React from 'react';

const ThreeBio = ({  }) => {
  // Your form logic and JSX go here

  return (
    <div className="p-20">
        <p>
        As the founder of the Constantine African Foundation, my vision is deeply rooted in the inspiration drawn from the esteemed 
        Patriarch Theodore II of Alexandria, reflecting my commitment as an Orthodox member of the Patriarchate of Alexandria and All Africa.
        Furthermore, my role as the Chief Secretary for the Metropolitan of Aksum has afforded me invaluable insights into leadership and 
        community engagement. With a career spanning over two decades in the hospitality industry, I bring a wealth of experience to the 
        Hospitality Industry. 
        </p>
        <p>
        With immense pride, I proudly assume the role of Treasurer (CFO) at the Constantine African Foundation, blending a distinguished 
        history in financial management with a versatile industry background. My proficiency and strategic decision-making align seamlessly 
        with a dedicated pursuit of professional growth and a fervent commitment to utilizing financial acumen for meaningful impact.
        </p>
        <p>
        Currently, I serve as CFO at Amshaj Manufacturing PLC, a prominent French-Lebanese company specializing in the production of 
        chemistry blood reagents and antiseptics. In this capacity, I've effectively overseen financial operations, showcasing my ability 
        to navigate complex financial landscapes and make sound decisions. My experience in managing diverse financial portfolios further 
        highlights my versatility and adaptability.
        </p>
        <p>
        Dedicated to continuous professional growth, I have pursued certifications, including completing preparation for the CMA 
        Certificate at Backer Institute, earning the Financial Management Certificate through Ernst & Young, and ongoing preparation 
        for the PBA Certificate by PMI. These certifications underscore my commitment to staying abreast of industry best practices 
        and enhancing my financial expertise continuously.
        </p>
        <p>
        Before my current role, I served as the General Manager of Siemens A.G., gaining valuable experience in financial management 
        across diverse sectors, including Power Station, Railway development, and Medical equipment. Ongoing collaboration with 
        Siemens in a partnership role strengthens my understanding of the challenges and opportunities within these industries.
        </p>
        <p>
        Joining the Constantine African Foundation, I bring exceptional organizational skills, effective task prioritization, 
        and a proven track record of fostering successful teamwork and individual project accomplishments. My adaptability to 
        challenging environments and capacity to perform under pressure align seamlessly with the dynamic nature of the CFO role. 
        Colleagues recognize me for being decisive, reliable, and exceptionally knowledgeable within the financial field.
        </p>
        <p>
        My extensive background, coupled with unwavering commitment to professional growth, positions me as a valuable asset to 
        your esteemed team. I am confident that my expertise and dedication will enable me to make significant contributions to 
        the Foundation's success.
        </p>
    </div>
  );
};

export default ThreeBio;